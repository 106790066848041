<template>
  <div>
    <div class="searchBox">
      <div class="searchLeft">
        <!-- <div>
          <el-input
              size="medium"
              clearable
              v-model="searchForm.name"
              placeholder="请输入banner名称"
              @keyup.enter.native="getTableList"
          ></el-input>
        </div>
        <el-button size="medium" type="primary" icon="el-icon-search" @click="getTableList(true)">搜索</el-button> -->
      </div>
      <div class="searchRight">
        <el-button
          size="medium"
          type="warning"
          icon="el-icon-plus"
          @click="addFunc"
          >新增</el-button
        >
      </div>
    </div>

    <el-table border :data="tableData" height="76vh" style="width: 99%">
      <el-table-column prop="id" label="档位ID"></el-table-column>
      <el-table-column prop="reward" label="奖励数量"></el-table-column>
      <el-table-column prop="inventory" label="库存"></el-table-column>
      <el-table-column prop="payType" label="是否最后开启">
        <template slot-scope="scope">
          <span>{{scope.row.last===1?'是':'否'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="discount" label="全屋房间IM消息">
        <template slot-scope="scope">
          <span>{{scope.row.broadcast===1?'是':'否'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <el-button size="small" type="primary" @click="toEdit(scope.row)"
            >编辑</el-button
          >
          <el-button
            size="small"
            type="danger" 
            @click="delFunc(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <pagination :totalNum="totalNum" :currentPage.sync="searchForm.pageNo" :pageSize.sync="searchForm.pageSize" @paginationChange="getTableList"></pagination>
    <el-dialog :title="`${ruleForm.id?'编辑':'添加'}商品`" :visible.sync="addVisible" width="600px">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="130px"
        class="demo-ruleForm"
      >
        <el-form-item label="档位ID" prop="id" v-if="ruleForm.id">
          <el-input
            placeholder="请输入内容"
            v-model="ruleForm.id"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="奖励数量" prop="reward" :rules="rules.blurRule">
          <el-input
            placeholder="请输入内容"
            v-model.number="ruleForm.reward"
            type="number"
          ></el-input>
        </el-form-item>
        <el-form-item label="库存" prop="inventory" :rules="rules.blurRule">
          <el-input
            placeholder="请输入内容"
            type="number"
            v-model.number="ruleForm.inventory"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否最后开启" prop="last" :rules="rules.changeRule">
          <el-radio-group v-model="ruleForm.last">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="全屋房间IM消息" prop="broadcast" :rules="rules.changeRule">
          <el-radio-group v-model="ruleForm.broadcast">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
        
      </el-form>
      
      
      <span slot="footer" class="dialog-footer">
        <el-button @click="addVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitFunc">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import pagination from '@/components/pagination'
export default {
  data() {
    return {
      totalNum: 0,
      addVisible: false,
      ruleForm: {},
      searchForm: {
        pageNo: 1,
        pageSize: 10
      },
      tableData: [],
      rules: {
        blurRule: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        changeRule: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        typeIcon: [{ required: true, message: "请上传图片", trigger: "change" }],
      },
    };
  },
  components:{pagination},
  created() {
    this.getTableList();
  },
  methods: {
    addFunc(){
      this.ruleForm = {}
      this.addVisible = true;
    },
    toEdit(data) {
      this.ruleForm = {...data};
      this.addVisible = true;
    },
    async submitFunc() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.ruleForm.initInventory = this.ruleForm.inventory
          let res = await this.$http.stayRewardBaseConfigSave({...this.ruleForm});
          if (res) {
            this.$message.success("操作成功！");
            this.getTableList();
            this.addVisible = false;
            this.$refs.ruleForm.resetFields();
          }
        }
      });
    },
    async getTableList(val) {
      if(val) {
        this.searchForm.pageNo = 1
      }
      let res = await this.$http.stayRewardBaseConfigList({...this.searchForm});
      if (res) {
        this.tableData = res.result.data;
        this.totalNum = res.result.totalCount;
      }
    },
    delFunc(row) {
      this.$confirm(`确定要删除么？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.stayRewardBaseConfigDel({id:row.id})
          if (res) {
            this.$message.success("操作成功！");
            this.getTableList();
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>

</style>
